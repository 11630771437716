.InputBox {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-top: 59vh;
    z-index: 0;
}

.Placeholder {
    margin-top: 130vh;
    z-index: -1;
    position: absolute;
    font-size: 72px
}

.animationForInputBox {
    display: block;
    animation-name: animate-text;
    animation-duration: 1.2s;
    animation-delay: 0s;
    animation-iteration-count: 1;
}

@keyframes animate-text {
    0% {
        opacity: 0%;
        left: 0px;
        transform: translateX(-10vw);
    }

    100% {

        opacity: 100%;
        transform: translateX(0vw);
    }
}

.button {
    border: none;
    color: rgb(156, 13, 13);
    padding: 0px 5px;
    text-align: center;
    font-size: 16px;
    margin: 4px 8px;
    transition-duration: 0.4s;
    cursor: pointer;
    z-index: 1;
}

.button1 {
    background-color: #33bbcf;
    color: rgb(158, 158, 158);
    border: 2px solid gray;
}

.button1:hover {
    background-color: white;
    color: white;
    box-shadow: 0px 0px 200px 0px rgba(255, 255, 255, 0.24), 0px 0px 16px 5px rgba(255, 255, 255, 0.24);
}

.form {
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.hidden {
    opacity: 0%;
}

.formTitle {
    font-size: 72px;
}



.btn {
    margin: 15px 0 40px 3px;
}

.btn button {
    padding: 9px 15px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 5000;
    background: linear-gradient(#214d76, #213976);
    border: none;
    color: #f2f2f2;
    cursor: pointer;
    letter-spacing: 1px;
    border-radius: 3px;
    outline: none;
}

.btn .tooltip {
    position: relative;
    margin-left: -10px;
    font-size: 17px;
    font-weight: 5000;
    color: #f2f2f2;
    z-index: -1;
    background: linear-gradient(#214d76, #213976);
    padding: 5px 10px;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s, margin-left 0.4s;
}

.btn .tooltip.show {
    margin-left: 10px;
    opacity: 1;
    pointer-events: auto;
}

.tooltip:before {
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    background: linear-gradient(#214d76, #213976);
    top: 50%;
    left: -5px;
    z-index: -1;
    transform: translateY(-50%) rotate(45deg);
}

.outputBox {
    color: white;
    background: linear-gradient(185.17deg, #272727 0%, #000000 100%);
    font-size: 18px;
    padding-top: 10px;
    padding-right: 0px;
    padding-left: 15px;
    padding-bottom: 10px;

}

.btn .copyBtn {
    margin: 5px 0 40px 15px;
}

@media all and (max-device-width: 720px) {

    .formTitle {
        font-size: 25px;
        overflow: hidden;
    }

    .InputBox {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-top: 35vh;
        z-index: 2;
    }

    .output {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        transform: translateY(-80px);
    }

    .Placeholder {
        margin-top: 50vh;
        opacity: 0%;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 50px;
        font-size: 2px;
    }

    .form {
        opacity: 100%;
        z-index: 2;
    }

    .outputBox {
        font-size: 16px;
        padding-top: 10px;
        padding-right: 0px;
        padding-left: 15px;
        padding-bottom: 10px;
        width: 90vw;
    }


    /* .animationForInputBox {} */
}