@tailwind base;
@tailwind components;
@tailwind utilities;

.home {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #214d76 0%, black 15%);
  position: fixed;

}

#root,
html,
body {
  width: 100vw;
  height: 100vh;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-gradient {
  background: radial-gradient(64.18% 64.18% at 71.16% 35.69%,
      #def9fa 0.89%,
      #bef3f5 17.23%,
      #9dedf0 42.04%,
      #7de7eb 55.12%,
      #5ce1e6 71.54%,
      #33bbcf 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@media all and (max-device-width: 720px) {

  .wrapper {
    position: relative;
    overflow: hidden;
  }
}