/* html,
body,
#root {
  width: 100%;
  height: 115%;
  margin: 0;
  padding: 0;
} */

.canvas {
  position: fixed;
  width: 100vw;
  height: 1000px;
  margin: 0;
  padding: 0;
  transform: translateY(40vh);
  background: linear-gradient(180deg, black 0%, #214d76 60%, #000000 100%);
  z-index: 3;
}

.spheres {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

}

/* .floor {
  position: relative;
} */

@media all and (max-device-width: 720px) {
  .canvas {
    position: absolute;
    font-size: 18px;
    margin-top: 70px;
    height: 500px;
  }
}