.moveTextDown {
    transform: translateY(20px);
}

.pageHeader {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    margin-top: 14vh;
    z-index: 2;
    font-size: 52px;
}

.urlShort {
    width: 395px;
}

.bg-discount-gradient {
    background: linear-gradient(185.17deg, #272727 0%, #000000 100%);
    padding-left: 10px;
    padding-right: 10px;
}

.moveScrollDownText {
    margin-left: 1vh;
}

.hidden {
    opacity: 0%;
}

.show {
    opacity: 100%;
}

@media all and (max-device-width: 720px) {

    .moveScrollDownText {
        opacity: 0%;
    }

    .pageHeader .urlShort {
        width: fit-content;
    }

    .pageHeader {
        font-size: 35px;
        margin-top: 50px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 310px;
    }

    .urlShort {
        width: 395px;
        margin-left: 15px;
    }
}